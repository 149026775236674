// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CustomerList-CustomerDetail-section-full{
        padding: 1% 5% 0% 5%;
        height: 100%;
        background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/containers/CustomerDetail/CustomerDetail.css"],"names":[],"mappings":"AAAA;QACQ,oBAAoB;QACpB,YAAY;QACZ,yBAAyB;AACjC","sourcesContent":["body .CustomerList-CustomerDetail-section-full{\n        padding: 1% 5% 0% 5%;\n        height: 100%;\n        background-color: #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
