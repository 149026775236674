import React from "react";
import { Typography, Paper } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { getCustomer } from "../../services/CustomerServices";
import { CustomNoRowsOverlay } from "../TableNoRowsOvlery";
import PersonIcon from "@mui/icons-material/Person";
import { setModelToCustomer } from "../../models/CustomerModel";
import { useTableRowContext } from "../../context/TableRowContext";
import clsx from "clsx";
import Cookies from "universal-cookie";
import AgreementModal from "../Modal/Modal";

import "./CustomerTable.css";
const CustomerTable = () => {
  const [customers, setCustomers] = React.useState({
    allRows: [],
    rows: [],
  });
  const cookies = new Cookies();
  const [fetchingData, setFetchingData] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    show: false,
    row: {},
    handleFunction: (e) => handleCloseModal(),
  });
  const { search } = useTableRowContext();
  function handleLookCustomer(row) {
    window.history.pushState(
      {},
      "",
      `/yms-admin/customer-list/customer?${row.row.scac_code}`
    );
  }

  const allColumns = [
    {
      field: "customer_name",
      headerName: "Customer Name",
      minWidth: 250,
    },

    {
      field: "scac_code",
      headerName: "SCAC Code",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "agreements_count",
      headerName: "Agreements",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "yard_storage",
      headerName: "Yard Storage",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <div className="CustomerList-CustomerTable-yard-storage-container">
          {params.value ? (
            params.value.map((yard, index) => (
              <Typography
                key={index}
                className="CustomerList-CustomerTable-yard-storage-item"
              >
                {yard.Name} - {yard.Quatity}
              </Typography>
            ))
          ) : (
            <Typography className="CustomerList-CustomerTable-yard-storage-item">
              N/A
            </Typography>
          )}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chassis_rental",
      headerName: "Chassis Rental",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <div className="CustomerList-CustomerTable-yard-storage-container">
          {Array.isArray(params.value) && params.value.length > 0 ? (
            params.value.map((yard, index) => (
              <Typography
                key={index}
                className="CustomerList-CustomerTable-yard-storage-item"
              >
                {yard.Name} - {yard.Quantity}
              </Typography>
            ))
          ) : (
            <Typography className="CustomerList-CustomerTable-yard-storage-item">
              N/A
            </Typography>
          )}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "yms_object",
      headerName: "YMS",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <div className="CustomerList-CustomerTable-yard-storage-container">
          {params.value ? (
            params.value.map((yard, index) => (
              <Typography
                key={index}
                className="CustomerList-CustomerTable-yard-storage-item"
              >
                {yard.Name} - {yard.Quatity}
              </Typography>
            ))
          ) : (
            <Typography className="CustomerList-CustomerTable-yard-storage-item">
              N/A
            </Typography>
          )}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 90,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<PersonIcon />}
          label="Person"
          onClick={() => handleLookCustomer(params)}
        />,
      ],
    },
  ];

  function handleCloseModal() {
    setModalInfo(() => ({
      show: false,
      row: {},
      handleFunction: (e) => handleCloseModal(),
    }));
  }

  function handleFetchData() {
    setFetchingData(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    getCustomer(sessionCosmos.scac_code).then((data) => {
      const customerList = setModelToCustomer(data);
      setCustomers({
        allRows: customerList,
        rows: customerList,
      });
      setFetchingData(false);
    });
  }

  function handleFilterBySearch() {
    if (!customers) return;
    const customersNew = customers.allRows;
    const filteredCustomers = customersNew.filter((customer) => {
      const customerString = Object.values(customer).join(" ").toLowerCase();
      return customerString.includes(search.toLowerCase());
    });
    setCustomers((customers) => ({
      ...customers,
      rows: filteredCustomers,
    }));
  }

  React.useEffect(() => {
    handleFetchData();
  }, []);

  React.useEffect(() => {
    handleFilterBySearch();
  }, [search]);

  const getRowId = (row) => row.id;
  return (
    <>
      <section className="CustomerList-CustomerTable-container">
        <Paper
          elevation={12}
          className="CustomerList-CustomerTable-paper-table"
        >
          <Typography
            variant="h5"
            className="CustomerList-CustomerTable-table-title"
          >
            Customer List
          </Typography>
          <div style={{ height: 500, minWidth: "100%" }}>
            <DataGrid
              getRowId={getRowId}
              rows={customers.rows || []}
              columns={allColumns}
              disableSelectionOnClick
              slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              sx={{ "--DataGrid-overlayHeight": "300px" }}
              loading={fetchingData}
            />
          </div>
        </Paper>
      </section>
      <AgreementModal modalInfo={modalInfo} />
    </>
  );
};

export default CustomerTable;
