function getInactiveDays(startDate, dateTo) {
  if (!dateTo) return "-- N/A --";

  const oneDay = 24 * 60 * 60 * 1000; // milisegundos en un día
  return Math.round((new Date(dateTo) - new Date(startDate)) / oneDay);
}

function returnFormattedDate(date) {
  //Aug 1, 2023
  if (!date) return "N/A";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
}
function getOveragePrice(OverageObject) {
  if (!OverageObject[0]) return null;
  return OverageObject[0].Cost;
}
function formatCurrency(value) {
  return `$${value.toFixed(2)}`;
}
export function setModelToCustomer(customerList) {
  let id = 1;
  return customerList.map((customer) => {
    return {
      id: id++,
      customer_name: customer.CustomerName,
      scac_code: customer.ScacCode,
      agreements_count: customer.QuantityAgreement,
      yard_storage: customer.YardStorage,
      chassis_rental: customer.ChassisRental,
      yms_object: customer.Yms,
    };
  });
}
