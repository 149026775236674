import axios from "axios";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const getCustomer = async (scac) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_CUSTOMER_DATA + scac,
      method: "GET",
    };

    const response = await axios(config);
    return response.data.Data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCustomerInfoByScac = async (scac) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_CARRIER_BY_SCAC + scac,
      method: "GET",
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAgreementsByOwnerAndCarrier = async (
  OwnerScacCode,
  CarrierScacCode
) => {
  try {
    const config = {
      headers,
      url:
        process.env.REACT_APP_GET_AGREEMENTS_BY_OWNER_AND_CARRIER +
        "OwnerScacCode=" +
        OwnerScacCode +
        "&CarrierScacCode=" +
        CarrierScacCode,
      method: "GET",
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
