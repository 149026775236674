import CustomerList from "./containers/CustomerList/CustomerList";
import CustomerDetail from "./containers/CustomerDetail/CustomerDetail";
import { TableRowProvider } from "./context/TableRowContext";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

export default function Root() {
  const router = createBrowserRouter([
    {
      path: "/yms-admin/customer-list",
      element: <CustomerList />,
    },
    {
      path: "/yms-admin/customer-list/:scac",
      element: <CustomerDetail />,
    },
  ]);
  return (
    <TableRowProvider>
      <RouterProvider router={router} />
    </TableRowProvider>
  );
}
