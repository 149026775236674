import React from "react";
import { getCustomerInfoByScac } from "../../services/CustomerServices";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./CustomerDetails.css";
import { TextField, Divider, Paper, Button } from "@mui/material";
import Cookies from "universal-cookie";
const CustomerDetails = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const cookies = new Cookies();
  const [customerInfo, setCustomerInfo] = React.useState(null);
  function getCustomerInfo() {
    const url = window.location.href;
    const ScacCode = url.split("customer?")[1];
    getCustomerInfoByScac(ScacCode).then((response) => {
      setCustomerInfo(response);
    });
  }

  function handleReturnToCustomerView() {
    window.history.pushState({}, "", "/yms-admin/customer-list");
  }
  React.useEffect(() => {
    getCustomerInfo();

    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <>
      <Grid
        container
        spacing={2}
        className="CustomerList-CustomerDetails-general-container"
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="CustomerList-CustomerDetail-grid-return-button"
        >
          <Button variant="text" onClick={() => handleReturnToCustomerView()}>
            Return To Customer View
          </Button>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={3} className="CustomerList-CustomerDetails-paper">
            <Grid
              container
              spacing={2}
              columns={13}
              className="CustomerList-CustomerDetails-grid-container"
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                container
                className="CustomerList-CustomerDetails-grid-container-textfields-customer"
              >
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="SCAC Code"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.ScacCode : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="Contact Name"
                    variant="outlined"
                    fullWidth
                    value={
                      customerInfo
                        ? customerInfo.BillingContact.NameContact
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={
                      customerInfo
                        ? customerInfo.BillingContact.EmailContact
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    value={
                      customerInfo
                        ? customerInfo.BillingContact.PhoneNumber
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="Address Line"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.AddressLine : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="City/District"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.City : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="State/Province"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.State : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
                  <TextField
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.PostalCode : ""}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={1}
                xl={1}
                className="CustomerList-CustomerDetails-grid-divider"
              >
                {width > 1200 ? (
                  <Divider orientation="vertical" fullHeight />
                ) : (
                  <Divider
                    orientation="horizontal"
                    fullWidth
                    className="CustomerList-CustomerDetails-horizontal-divider"
                  />
                )}
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                container
                className="CustomerList-CustomerDetails-grid-container-textfields-customer"
              >
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="Address Line"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.AddressLine : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="City/District"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.City : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="State/Province"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.State : ""}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    value={customerInfo ? customerInfo.Address.PostalCode : ""}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={2}></Grid>
      </Grid>
    </>
  );
};

export default CustomerDetails;
