import CustomerDetails from "../../components/CustomerDetails/CustomerDetails";
import AgreementListTable from "../../components/AgreementListTable/AgreementListTable";
import "./CustomerDetail.css";
const CustomerDetail = () => {
  return (
    <section
      style={{
        height: "100vh",
      }}
    >
      <section className="CustomerList-CustomerDetail-section-full">
        <CustomerDetails />
        <AgreementListTable />
      </section>
    </section>
  );
};
export default CustomerDetail;
