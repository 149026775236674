// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CustomerList-CustomerDetails-grid-container-textfields-customer {
    display: flex;
    justify-content: center;
}

body .CustomerList-CustomerDetails-grid-divider {
    display: flex;
    justify-content: center;
}

body .CustomerList-CustomerDetails-general-container {}

body .CustomerList-CustomerDetails-paper {
    padding: 4% 5% 4% 5%;
    border-radius: 12px;
}

body .CustomerList-CustomerDetails-grid-container {
    display: flex;
    justify-content: center;
}

body .CustomerList-CustomerDetails-horizontal-divider {
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    margin: 2% 0% 2% 0%;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomerDetails/CustomerDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA,sDAAsD;;AAEtD;IACI,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":["body .CustomerList-CustomerDetails-grid-container-textfields-customer {\n    display: flex;\n    justify-content: center;\n}\n\nbody .CustomerList-CustomerDetails-grid-divider {\n    display: flex;\n    justify-content: center;\n}\n\nbody .CustomerList-CustomerDetails-general-container {}\n\nbody .CustomerList-CustomerDetails-paper {\n    padding: 4% 5% 4% 5%;\n    border-radius: 12px;\n}\n\nbody .CustomerList-CustomerDetails-grid-container {\n    display: flex;\n    justify-content: center;\n}\n\nbody .CustomerList-CustomerDetails-horizontal-divider {\n    border: 1px solid #E0E0E0;\n    border-radius: 20px;\n    margin: 2% 0% 2% 0%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
