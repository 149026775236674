import { Typography, Paper } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./TableNoRowsOvlery";
import { setModelToAgreements } from "../../models/AgreementModel";
import { getAgreementsByOwnerAndCarrier } from "../../services/CustomerServices";
import "./AgreementListTable.css";
import React from "react";
import Cookies from "universal-cookie";
import clsx from "clsx";
const AgreementListTable = () => {
  const cookies = new Cookies();
  const [agreements, setAgreements] = React.useState({
    allRows: [],
    rows: [],
  });
  const [fetchingData, setFetchingData] = React.useState(false);
  const someColumns = [
    {
      field: "cod",
      headerName: "COD",
      minWidth: 150,
      header: "center",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      cellClassName: (params) => {
        const status = params.value.toLowerCase(); // Convertir a minúsculas
        return clsx({
          "CustomerList-AgreementList-status-column-active":
            status === "activated",
          "CustomerList-AgreementList-status-column-not-active":
            status === "not active",
        });
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      cellClassName: (params) => {
        return clsx("CustomerList-AgreementList-type-column");
      },
      headerAlign: "center",
      align: "center",
    },

    {
      field: "inactive",
      headerName: "Time Inactive",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spots",
      headerName: "# of Spots",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "loc_chassis",
      headerName: "Loc/Chassis",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "over_price",
      headerName: "Over. Price",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "over_chassis",
      headerName: "Over. Chassis",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billing_start",
      headerName: "Billing Start",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "billing_end",
      headerName: "Billing End",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
  ];
  function handleFetchData() {
    setFetchingData(true);
    const url = window.location.href;
    const CarrierScacCode = url.split("customer?")[1];
    const sessionCosmos = cookies.get("sessionCosmos");
    getAgreementsByOwnerAndCarrier(
      sessionCosmos.scac_code,
      CarrierScacCode
    ).then((data) => {
      const agreementList = setModelToAgreements(data.Data);
      setAgreements({
        allRows: agreementList,
        rows: agreementList,
      });
      setFetchingData(false);
    });
  }
  React.useEffect(() => {
    handleFetchData();
  }, []);

  const getRowId = (row) => row.cod;

  return (
    <Paper elevation={12} className="CustomerList-AgreementList-paper-table">
      <Typography
        variant="h5"
        className="CustomerList-AgreementList-table-title"
      >
        Agreement List
      </Typography>
      <div style={{ height: 400, minWidth: "100%" }}>
        <DataGrid
          getRowId={getRowId}
          rows={agreements.rows || []}
          columns={someColumns}
          disableSelectionOnClick
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          loading={fetchingData}
        />
      </div>
    </Paper>
  );
};

export default AgreementListTable;
