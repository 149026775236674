import { CustomerRow } from "../../components/CustomerRow/CustomerRow";
import CustomerTable from "../../components/CustomerTable/CustomerTable";

const CustomerList = () => {
  return (
    <section
      style={{
        height: "100vh",
      }}
    >
      <section style={{ height: "100%", backgroundColor: "#f0f0f0" }}>
        <CustomerRow />
        <CustomerTable />
      </section>
    </section>
  );
};
export default CustomerList;
