function getInactiveDays(startDate, dateTo) {
  if (!dateTo) return "-- N/A --";

  const oneDay = 24 * 60 * 60 * 1000; // milisegundos en un día
  return Math.round((new Date(dateTo) - new Date(startDate)) / oneDay);
}

function returnFormattedDate(date) {
  //Aug 1, 2023
  if (!date) return "N/A";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
}
function getOveragePrice(OverageObject) {
  if (!OverageObject[0]) return null;
  return OverageObject[0].Cost;
}
function formatCurrency(value) {
  if (!value) return "-- N/A --";
  return `$${value.toFixed(2)}`;
}
export function setModelToAgreements(agreementList) {
  return agreementList.map((agreement) => {
    return {
      cod: agreement.AgreementId,
      status: agreement.Status !== "Activated" ? "Not Active" : "Activated",
      type: agreement.Service.Frequency,
      inactive: getInactiveDays(
        agreement.AgreementDates.ValidFromUTC,
        agreement.AgreementDates.ValidToUTC
      ),
      spots: agreement.Service.SpotsRented,
      loc_chassis: agreement.Service.YardRentedCodeName,
      price: formatCurrency(agreement.Service.Regular[0].Cost),
      over_price: formatCurrency(getOveragePrice(agreement.Service.Overage)),
      over_chassis: formatCurrency(getOveragePrice(agreement.Service.Overage)),

      billing_start: returnFormattedDate(agreement.AgreementDates.ValidFromUTC),
      billing_end: returnFormattedDate(agreement.AgreementDates.ValidToUTC),
    };
  });
}
