// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .AgreementList-AgreementRow-button-customer-grid {
    display: flex;
    justify-content: end;
}

body .AgreementList-AgreementRow-container {
    padding: 3% 5% 10px 5%;
}

body .AgreementList-AgreementRow-button-customer {
    background-color: #fc4818;
    font-size: 100%;
    font-weight: 400;
    text-transform: capitalize;
}

body .AgreementList-AgreementRow-switch-grid {
    display: flex;
    justify-content: start;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomerRow/CustomerRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":["body .AgreementList-AgreementRow-button-customer-grid {\n    display: flex;\n    justify-content: end;\n}\n\nbody .AgreementList-AgreementRow-container {\n    padding: 3% 5% 10px 5%;\n}\n\nbody .AgreementList-AgreementRow-button-customer {\n    background-color: #fc4818;\n    font-size: 100%;\n    font-weight: 400;\n    text-transform: capitalize;\n}\n\nbody .AgreementList-AgreementRow-switch-grid {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
