// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .AgreementList-Modal-backdrop-props {
    backdrop-filter: blur(15px);
    background-color: rgba(34, 34, 79, 0.3);
}

body .AgreementList-Modal-paper-props {
    border-radius: 16px;
    padding: 5%
}

body .AgreementList-Modal-grid-title {}

body .AgreementList-Modal-paper {
    background-color: #F7F7F7;
    border: 2px #D1D1D1 solid;
    border-radius: 12px;
}

body .AgreementList-Modal-textfield-spots {
    background-color: white;
    border-radius: 8px;
    height: 40px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
}

body .AgreementList-Modal-save-button {
    background-color: #fc4818;
    color: white;
    width: 100%;
    font-weight: 600;

}

body .AgreementList-Modal-textfield-date-updated {
    font-size: 60%;
    color: #B1B1B1;
}

body .AgreementList-Modal-container-grid-paper {
    padding: 3% 5% 3% 5%;
    display: flex;
    align-items: center;

}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;IACnB;AACJ;;AAEA,sCAAsC;;AAEtC;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,mBAAmB;;AAEvB","sourcesContent":["body .AgreementList-Modal-backdrop-props {\n    backdrop-filter: blur(15px);\n    background-color: rgba(34, 34, 79, 0.3);\n}\n\nbody .AgreementList-Modal-paper-props {\n    border-radius: 16px;\n    padding: 5%\n}\n\nbody .AgreementList-Modal-grid-title {}\n\nbody .AgreementList-Modal-paper {\n    background-color: #F7F7F7;\n    border: 2px #D1D1D1 solid;\n    border-radius: 12px;\n}\n\nbody .AgreementList-Modal-textfield-spots {\n    background-color: white;\n    border-radius: 8px;\n    height: 40px;\n    padding: 0;\n    align-items: center;\n    display: flex;\n    justify-content: center;\n}\n\nbody .AgreementList-Modal-save-button {\n    background-color: #fc4818;\n    color: white;\n    width: 100%;\n    font-weight: 600;\n\n}\n\nbody .AgreementList-Modal-textfield-date-updated {\n    font-size: 60%;\n    color: #B1B1B1;\n}\n\nbody .AgreementList-Modal-container-grid-paper {\n    padding: 3% 5% 3% 5%;\n    display: flex;\n    align-items: center;\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
