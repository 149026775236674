import React from "react";
import {
  Container,
  TextField,
  Switch,
  Button,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import "./CustomerRow.css";
import { useTableRowContext } from "../../context/TableRowContext";

export const CustomerRow = () => {
  const { handleChangeShowAllColumns, search, setSearchValue } =
    useTableRowContext();

  function handleCustomerButton() {
    window.history.pushState({}, "", "/yms-admin/agreement-list");
  }
  return (
    <section className="AgreementList-AgreementRow-container">
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={2}>
          <TextField
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { maxHeight: "40px" },
            }}
            value={search}
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid xs={0} sm={0} md={4} lg={7} xl={8}></Grid>
        <Grid
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          className="AgreementList-AgreementRow-button-customer-grid"
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleCustomerButton()}
            className="AgreementList-AgreementRow-button-customer"
          >
            Agreements
          </Button>
        </Grid>
      </Grid>
    </section>
  );
};
