import {
  Dialog,
  TextField,
  Typography,
  Paper,
  Button,
  Backdrop,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import "./Modal.css";
const AgreementModal = ({ modalInfo }) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={modalInfo.show}
        onClose={() => modalInfo.handleFunction()}
        PaperProps={{
          className: "AgreementList-Modal-paper-props",
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          className: "AgreementList-Modal-backdrop-props",
        }}
      >
        <Grid spacing={2} container>
          <Grid
            xs={3}
            sm={3}
            md={2}
            lg={3}
            xl={3}
            className="AgreementList-Modal-grid-title"
          ></Grid>
        </Grid>
        <Paper className="AgreementList-Modal-paper">
          <Grid
            spacing={2}
            container
            columns={16}
            className="AgreementList-Modal-container-grid-paper"
          >
            <Grid
              xs={16}
              sm={16}
              md={5}
              lg={4}
              xl={4}
              className="AgreementList-Modal-grid-title"
            >
              <Typography variant="h6"># of spots next month</Typography>
            </Grid>
            <Grid
              xs={16}
              sm={16}
              md={2}
              lg={1}
              xl={1}
              className="AgreementList-Modal-grid-title"
            >
              <TextField
                value={modalInfo.row?.row?.spots || 0}
                fullWidth
                disabled
                variant="filled"
                inputProps={{
                  className: "AgreementList-Modal-textfield-spots",
                }}
              />
            </Grid>
            <Grid xs={16} sm={16} md={3} lg={2} xl={2}>
              <Button className="AgreementList-Modal-save-button">Save</Button>
            </Grid>
            <Grid xs={16} sm={16} md={3} lg={3} xl={3}>
              <Typography className="AgreementList-Modal-textfield-date-updated">
                Last Updated: 3/18/24, 13:14
              </Typography>
            </Grid>{" "}
            <Grid xs={16} sm={16} md={3} lg={5} xl={5} />
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Type"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.loc_chassis}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Type"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.type}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Billing Start"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.billing_start}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Billing End"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.billing_end}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Price"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.price}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Overage Price"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.over_price}
              />
            </Grid>
            <Grid xs={16} sm={8} md={4} lg={2} xl={2}>
              <TextField
                fullWidth
                label="Overage Chassis"
                className="AgreementList-Modal-textfield-date-updated"
                value={modalInfo.row?.row?.over_chassis}
                onChange={(e) => console.log(e)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default AgreementModal;
