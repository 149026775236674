// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CustomerList-AgreementList-table-title {
    background-color: #1578fc;
    color: white;
    font-weight: 600;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
}

body .CustomerList-AgreementList-type-column {
    color: #1578fc;
    font-weight: 700;
}

body .CustomerList-AgreementList-status-column-not-active {
    color: #D35100;
    font-weight: 700;
}

body .CustomerList-AgreementList-status-column-active {
    color: #26B54D;
    font-weight: 700;
}

body .CustomerList-AgreementList-paper-table {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/AgreementListTable/AgreementListTable.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,4BAA4B;IAC5B,6BAA6B;IAC7B,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC","sourcesContent":["body .CustomerList-AgreementList-table-title {\n    background-color: #1578fc;\n    color: white;\n    font-weight: 600;\n    text-align: center;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    padding: 10px;\n}\n\nbody .CustomerList-AgreementList-type-column {\n    color: #1578fc;\n    font-weight: 700;\n}\n\nbody .CustomerList-AgreementList-status-column-not-active {\n    color: #D35100;\n    font-weight: 700;\n}\n\nbody .CustomerList-AgreementList-status-column-active {\n    color: #26B54D;\n    font-weight: 700;\n}\n\nbody .CustomerList-AgreementList-paper-table {\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
